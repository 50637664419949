<template>
  <travio-center-container grid-width="2/3" :pageTitle="pageTitle">
    <vx-card>
      <!-- <div v-if="isEditForm" class="vx-row mb-6">
        <div class="vx-col w-full align-center">
          <span>Extra Id</span>
        </div>
        <div class="vx-col w-full">
          <vs-input class="w-full" name="id" v-model="form.id" :disabled="true" />
        </div>
      </div> -->

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="vx-row mb-4">
            <div class="vx-col w-full align-center"><span>Extra Name* (for passenger price basis, use {PAXTYPE} to include the passenger type in the name)</span></div>
            <div class="vx-col w-full">
              <vs-input class="w-full" name="name" v-model="form.name" v-validate="'required|max:100'" :disabled="readOnly" />
              <span class="text-danger text-sm">{{ errors.first("name") }}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-full">
          <div class="vx-row">
            <div class="vx-col w-full align-center"><span>Extra Description*</span></div>
            <div class="vx-col w-full">
              <vs-textarea 
                name="Description"
                rows="3" 
                v-model="form.description"
                :disabled="readOnly"
                v-validate="'required|max:500'"
                class="w-full"/>
              <span class="text-danger text-sm">{{ errors.first("Description") }}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-full">
          <div class="vx-row mb-4">
            <div class="vx-col w-full"><span>Extra Type:</span></div>
            <div class="vx-col w-full">
              <v-select v-model="form.extraType" name="Extra Type" :options="priceRulesFormData.extraTypes" :reduce="(x) => x.code" :disabled="readOnly" />
              <span class="text-danger text-sm">{{errors.first("Extra Type")}}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-full">
          <div class="vx-row mb-4">
            <div class="vx-col w-full"><span>Extra Features:</span></div>
            <div class="vx-col w-full">
              <v-select v-model="form.extraFeatures" name="Extra Features" multiple :options="priceRulesFormData.extraFeatures" :reduce="(x) => x.code" :disabled="readOnly" />
              <span class="text-danger text-sm">{{errors.first("Extra Features")}}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-full">
          <div class="vx-row mb-4">
            <div class="vx-col w-full align-center"><span>Extra Categories</span></div>
            <div class="vx-col w-full">
              <vs-input class="w-full" name="Extra Categories" v-model="form.extraCategories" v-validate="" :disabled="readOnly" />
              <span class="text-danger text-sm">{{ errors.first("Extra Categories") }}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-4">
            <div class="vx-col w-full"><span>Price Basis:</span></div>
            <div class="vx-col w-full">
              <v-select v-model="form.priceBasis" name="Price Basis" :options="priceBasisOptions" :reduce="(x) => x.code" v-validate="'required'" :disabled="readOnly" />
              <span class="text-danger text-sm">{{errors.first("Price Basis")}}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-4">
            <div class="vx-col w-full"><span>Currency:</span></div>
            <div class="vx-col w-full">
              <v-select v-model="form.currency" name="Currency" :options="currencyOptions" :reduce="(x) => x.code" v-validate="'required'" :disabled="readOnly" />
              <span class="text-danger text-sm">{{errors.first("Currency")}}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-full md:w-1/3">
          <div class="vx-row mb-4">
            <div class="vx-col w-full"><span>Adult Price:</span></div>
            <div class="vx-col w-full">
              <vs-input class="w-full" v-model.number="form.adultPrice" :disabled="readOnly" type="number" min="0" name="Adult Price" v-validate="'required|min_value:0'" />
              <span class="text-danger text-sm">{{errors.first("Adult Price")}}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-full md:w-1/3">
          <div class="vx-row mb-4">
            <div class="vx-col w-full"><span>Child Price:</span></div>
            <div class="vx-col w-full">
              <vs-input class="w-full" v-model.number="form.childPrice" :disabled="readOnly" type="number" min="0" name="Child Price" v-validate="'required|min_value:0'" />
              <span class="text-danger text-sm">{{errors.first("Child Price")}}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-full md:w-1/3">
          <div class="vx-row mb-4">
            <div class="vx-col w-full"><span>Infant Price:</span></div>
            <div class="vx-col w-full">
              <vs-input class="w-full" v-model.number="form.infantPrice" :disabled="readOnly" type="number" min="0" name="Infant Price" v-validate="'required|min_value:0'" />
              <span class="text-danger text-sm">{{errors.first("Infant Price")}}</span>
            </div>
          </div>
        </div>
       
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-4">
            <div class="vx-col w-full">
              <span>Max Qty:</span>
            </div>
            <div class="vx-col w-full">
              <v-select name="Max Qty" v-model="form.maxQty" :options="helper.numberArray(1, 20)" v-validate="'required'" :disabled="readOnly" />
              <span class="text-danger text-sm">{{errors.first("Max Qty")}}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-4">
            <div class="vx-col w-full">
              <span>Days Before Travel:</span>
            </div>
            <div class="vx-col w-full">
              <v-select name="Days Before Travel" v-model="form.daysBeforeTravel" :options="helper.numberArray(1, 365)" :disabled="readOnly" />
            </div>
          </div>
        </div>

        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <span>Active:</span>
            </div>
            <div class="vx-col w-full">
              <vs-switch class="mt-2" v-model="form.isActive" :disabled="readOnly" />
            </div>
          </div>
        </div>

        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-4">
            <div class="vx-col w-full"></div>
          </div>
        </div>

        <!--#region Passangers Allocations-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <span>Passenger Allocations:</span>
            </div>
            <template v-for="(passanger, passangerIndex) in form.passengerAllocations">
              <!-- Adults -->
              <div class="vx-col w-full md:w-1/4" :key="passangerIndex + '0'">
                <div class="mt-4">
                  <label class="text-sm">Adults</label>
                  <vs-input class="w-full" type="number" min="0" :name="'adults_' + passangerIndex"
                    v-model="form.passengerAllocations[passangerIndex].adults"
                    v-validate="'required|min_value:0|max_value:100'" :disabled="readOnly" />
                  <!-- <span class="text-danger text-sm" v-if="errors.has('adults_' + passangerIndex)">{{ errors.first('adults_'+passangerIndex).replace('adults_'+passangerIndex, "adults") }}</span> -->
                </div>
              </div>
              <!-- Children -->
              <div class="vx-col w-full md:w-1/4" :key="passangerIndex + '1'">
                <div class="mt-4">
                  <label class="text-sm">Children</label>
                  <vs-input class="w-full" min="0" type="number" :name="'children_' + passangerIndex"
                    v-model="form.passengerAllocations[passangerIndex].children"
                    v-validate="'required|min_value:0|max_value:100'" :disabled="readOnly" />
                  <!-- <span class="text-danger text-sm" v-if="errors.has('children_' + passangerIndex)">{{ errors.first('children_'+passangerIndex).replace('children_'+passangerIndex, "children") }}</span> -->
                </div>
              </div>
              <!-- Infants -->
              <div class="vx-col w-full md:w-1/4" :key="passangerIndex + '2'">
                <div class="mt-4">
                  <label class="text-sm">Infants</label>
                  <vs-input class="w-full" min="0" type="number" :name="'infants_' + passangerIndex"
                    v-model="form.passengerAllocations[passangerIndex].infants"
                    v-validate="'required|min_value:0|max_value:100'" :disabled="readOnly" />
                  <!-- <span class="text-danger text-sm" v-if="errors.has('infants_' + passangerIndex)">{{ errors.first('infants_'+passangerIndex).replace('infants_'+passangerIndex, "infants") }}</span> -->
                </div>
              </div>
              <div class="vx-col w-full md:w-1/4" :key="passangerIndex + '3'">
                <div class="mt-3">
                  <!-- <label class="text-sm">Max</label> -->
                  <vs-button @click="removeDynamicFields(passangerIndex, 'passenger')" size="small" class="mt-8"
                    type="border" color="danger" :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                    :disabled="readOnly">Remove</vs-button>
                </div>
              </div>
            </template>

            <div class="vx-col w-full my-3">
              <vs-button @click="addDynamicFields('passenger')" size="small" type="border" color="success"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''" :disabled="readOnly">Add</vs-button>
            </div>
          </div>
        </div>

        <!--#endregion Passangers Allocations ends-->

        <!--#region Price Range field  -->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <span>Price Ranges:</span>
            </div>
            <template v-for="(price, priceIndex) in form.prices">
              <!-- Min -->
              <div class="vx-col w-full md:w-1/3" :key="priceIndex + '0'">
                <div class="mt-4">
                  <label class="text-sm">Min</label>
                  <vs-input class="w-full" min="0" type="number" :name="'priceMin_' + priceIndex"
                    v-model="form.prices[priceIndex].min" v-validate="{
                      rules: {
                        required: true,
                        max_value: form.prices[priceIndex].max
                          ? form.prices[priceIndex].max
                          : false,
                      },
                    }" :disabled="readOnly" />
                  <span class="text-danger text-sm" v-if="errors.has('priceMin_' + priceIndex)">{{
                      errors
                        .first("priceMin_" + priceIndex)
                        .replace("The priceMin_" + priceIndex, "Min price ")
                  }}</span>
                </div>
              </div>
              <!-- Max -->
              <div class="vx-col w-full md:w-1/3" :key="priceIndex + '1'">
                <div class="mt-4">
                  <label class="text-sm">Max</label>
                  <vs-input class="w-full" min="0" type="number" :name="'priceMax_' + priceIndex"
                    v-model="form.prices[priceIndex].max" v-validate="{
                      rules: {
                        required: true,
                        min_value: form.prices[priceIndex].min
                          ? form.prices[priceIndex].min
                          : false,
                      },
                    }" :disabled="readOnly" />
                  <span class="text-danger text-sm" v-if="errors.has('priceMax_' + priceIndex)">{{
                      errors
                        .first("priceMax_" + priceIndex)
                        .replace("The priceMax_" + priceIndex, "Max price ")
                  }}</span>
                </div>
              </div>

              <div class="vx-col w-full md:w-1/3" :key="priceIndex + '3'">
                <div class="mt-3">
                  <!-- <label class="text-sm">Remove</label> -->
                  <vs-button @click="removeDynamicFields(priceIndex, 'priceRange')" size="small" class="mt-8"
                    type="border" color="danger" :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                    :disabled="readOnly">Remove</vs-button>
                </div>
              </div>
            </template>

            <div class="vx-col w-full my-3">
              <vs-button @click="addDynamicFields('priceRange')" size="small" type="border" color="success"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''" :disabled="readOnly">Add</vs-button>
            </div>
          </div>
        </div>
        <!--#endregion Price Range  field ends -->

        <!--#region Booking Dates field  -->
        <div class="vx-col w-full">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <span>Booking Dates:</span>
            </div>
            <template v-for="(bookingDate, dateIndex) in form.bookingDates">
              <!-- Min -->
              <div class="vx-col w-full md:w-1/5" :key="dateIndex + '0'">
                <div class="mt-4">
                  <label class="text-sm">Min</label>
                  <flat-pickr v-model="form.bookingDates[dateIndex].min" :name="'bookMin_' + dateIndex"
                    v-validate="'required'" :config="{
                      dateFormat: 'Y-m-d',
                      maxDate: form.bookingDates[dateIndex].max,
                    }" class="w-full" :disabled="readOnly" />
                  <span class="text-danger text-sm" v-if="errors.has('bookMin_' + dateIndex)">{{
                      errors
                        .first("bookMin_" + dateIndex)
                        .replace(
                          "The bookMin_" + dateIndex + " field is required",
                          "Min date is required"
                        )
                  }}</span>
                </div>
              </div>
              <!-- Max -->
              <div class="vx-col w-full md:w-1/5" :key="dateIndex + '1'">
                <div class="mt-4">
                  <label class="text-sm">Max</label>
                  <flat-pickr v-model="form.bookingDates[dateIndex].max" :config="{
                    dateFormat: 'Y-m-d',
                    minDate: form.bookingDates[dateIndex].min,
                  }" class="w-full" :name="'bookMax_' + dateIndex" v-validate="'required'" :disabled="readOnly" />
                  <span class="text-danger text-sm" v-if="errors.has('bookMax_' + dateIndex)">{{
                      errors
                        .first("bookMax_" + dateIndex)
                        .replace(
                          "The bookMax_" + dateIndex + " field is required",
                          "Max date is required"
                        )
                  }}</span>
                </div>
              </div>
              <!-- Days -->
              <div class="vx-col w-full md:w-2/5" :key="dateIndex + '2'">
                <div class="mt-4">
                  <label class="text-sm">Days</label>
                  <v-select class="w-full" name="daysOfWeek" multiple v-model="form.bookingDates[dateIndex].daysOfWeek"
                    :options="helper.enums.daysOfWeek" :reduce="(option) => option.code" v-validate="{
                      rules: { required: false },
                    }" :disabled="readOnly" />
                  <!-- <span class="text-danger text-sm"  v-show="errors.has('daysOfWeek')">{{ errors.first('daysOfWeek') }}</span> -->
                </div>
              </div>
              <div class="vx-col w-full md:w-1/5" :key="dateIndex + '3'">
                <div class="mt-3">
                  <!-- <label class="text-sm">Remove</label> -->
                  <vs-button @click="removeDynamicFields(dateIndex, 'bookingDates')" size="small" class="mt-8"
                    type="border" color="danger" :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                    :disabled="readOnly">Remove</vs-button>
                </div>
              </div>
            </template>

            <div class="vx-col w-full my-3">
              <vs-button @click="addDynamicFields('bookingDates')" size="small" type="border" color="success"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''" :disabled="readOnly">Add</vs-button>
            </div>
          </div>
        </div>
        <!--#endregion Booking Dates field ends -->

        <!--#region Travel Dates field  -->
        <div class="vx-col w-full">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <span>Travel Dates:</span>
            </div>
            <template v-for="(travelDate, dateIndex) in form.travelDates">
              <!-- Min -->
              <div class="vx-col w-full md:w-1/5" :key="dateIndex + '0'">
                <div class="mt-4">
                  <label class="text-sm">Min</label>
                  <flat-pickr v-model="form.travelDates[dateIndex].min" :config="{
                    dateFormat: 'Y-m-d',
                    maxDate: form.travelDates[dateIndex].max,
                  }" class="w-full" :name="'travelMin_' + dateIndex" v-validate="'required'" :disabled="readOnly" />
                  <span class="text-danger text-sm" v-if="errors.has('travelMin_' + dateIndex)">{{
                      errors
                        .first("travelMin_" + dateIndex)
                        .replace(
                          "The travelMin_" + dateIndex + " field is required",
                          "Min date is required"
                        )
                  }}</span>
                </div>
              </div>
              <!-- Max -->
              <div class="vx-col w-full md:w-1/5" :key="dateIndex + '1'">
                <div class="mt-4">
                  <label class="text-sm">Max</label>
                  <flat-pickr v-model="form.travelDates[dateIndex].max" :config="{
                    dateFormat: 'Y-m-d',
                    minDate: form.travelDates[dateIndex].min,
                  }" class="w-full" :name="'travelMax_' + dateIndex" :disabled="readOnly" v-validate="'required'" />
                  <span class="text-danger text-sm" v-if="errors.has('travelMax_' + dateIndex)">{{
                      errors
                        .first("travelMax_" + dateIndex)
                        .replace(
                          "The travelMax_" + dateIndex + " field is required",
                          "Max date is required"
                        )
                  }}</span>
                </div>
              </div>
              <!-- Days -->
              <div class="vx-col w-full md:w-2/5" :key="dateIndex + '2'">
                <div class="mt-4">
                  <label class="text-sm">Days</label>
                  <v-select class="w-full" name="daysOfWeek" multiple v-model="form.travelDates[dateIndex].daysOfWeek"
                    :options="helper.enums.daysOfWeek" :reduce="(option) => option.code" v-validate="{
                      rules: { required: false },
                    }" :disabled="readOnly" />
                </div>
              </div>
              <div class="vx-col w-full md:w-1/5" :key="dateIndex + '3'">
                <div class="mt-3">
                  <!-- <label class="text-sm">Max</label> -->
                  <vs-button @click="removeDynamicFields(dateIndex, 'travelDates')" size="small" class="mt-8"
                    type="border" color="danger" :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                    :disabled="readOnly">
                    Remove
                  </vs-button>
                </div>
              </div>
            </template>

            <div class="vx-col w-full my-3">
              <vs-button @click="addDynamicFields('travelDates')" size="small" type="border" color="success"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''" :disabled="readOnly">Add</vs-button>
            </div>
          </div>
        </div>
        <!--#endregion Travel Dates field ends -->
      </div>

      <!--#endregion Product Type field ends -->

      <!--#region Search Type field -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <span>Search Types:</span>
        </div>
        <template v-for="(searchType, searchIndex) in helper.enums.searchTypes">
          <div class="vx-col flex flex-wrap w-1/2 mb-4 mt-4" :key="searchIndex">
            <vs-checkbox :vs-value="searchType.value" :title="searchType" v-model="form.searchTypes"
              :disabled="readOnly">{{ searchType.displayName }}</vs-checkbox>
          </div>
        </template>
      </div>
      <!--#endregion Search Type field ends-->

      <!--#region Product Type field -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <span>Product Types:</span>
        </div>
        <template v-for="(product, productIndex) in helper.enums.productTypes">
          <div class="vx-col flex flex-wrap w-1/2 mb-4 mt-4" :key="productIndex">
            <vs-checkbox :vs-value="product.value" :title="product" v-model="form.productTypes" :disabled="readOnly">{{
                product.displayName
            }}</vs-checkbox>
          </div>
          <component :key="productIndex + '1'" class="vx-col w-full"
            v-show="product.componentName && form.productTypes.includes(product.value)" :is="product.componentName"
            :form="form" :helper="helper" :priceRulesFormData="priceRulesFormData" :readOnly="readOnly"
            :ref="pricingRuleChildComponent"></component>
        </template>
      </div>

      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div class="flex flex-wrap justify-end">
        <vs-button v-if="!readOnly" @click="handleCancel" color="danger" class="ml-auto mt-4 mr-4">Cancel</vs-button>
        <vs-button v-if="!readOnly" @click="handleSubmit" class="mt-4">Save</vs-button>
        <vs-button v-if="readOnly && !isSystemRole" @click="handleEditMode" class="ml-auto mt-4 mr-4">Edit</vs-button>
        <vs-button v-if="readOnly" @click="handleCancel" class="mt-4">Back</vs-button>
      </div>
      <!--#region product type sub-criteria not selection warning-->
      <vs-prompt title="" accept-text="Proceed" @cancel="proceedSaving = false" @close="proceedSaving = false" @accept="
        proceedSaving = true;
      handleSubmit();
      " :active.sync="displayProductTypeWarning">
        <div class="vx-row mt-2 mb-4">
          <div class="vx-col">
            <p>
              Not all of the additional criterias have been set up for following
              product types
            </p>
            <br />
            <p>
              <strong> {{ productTypesWithoutRule.join() }} </strong>
            </p>
            <br />
            <p>If you want to save anyway, click proceed button</p>
          </div>
        </div>
      </vs-prompt>
      <!--#endregion product type sub-criteria not selection warning ends-->
    </vx-card>
  </travio-center-container>
</template>

<script>
import { Form } from "@/core-modules/form-framework/Form.js";
import helper from "./customExtraHelper";
import customExtraStore from "./customExtraStore";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import _ from "lodash";
import { CustomValidator } from "./CustomExtraEditorValidator.js";
import FlightRuleCriteria from "./sub-criterias/FlightRuleCriteria.vue";
import AirportExtrasCriteria from "./sub-criterias/AirportExtrasCriteria.vue";
import TicketsCriteria from "./sub-criterias/TicketsCriteria.vue";
import TransferRuleCriteria from "./sub-criterias/TransferRuleCriteria.vue";
import CarCriteria from "./sub-criterias/CarCriteria.vue";
import AccommodationRuleCriteria from "./sub-criterias/AccommodationRuleCriteria.vue";
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';

const customvalidator = new CustomValidator();
customvalidator.setupErrorMessages();

export default {
  components: {
    flatPickr,
    FlightRuleCriteria,
    AirportExtrasCriteria,
    TicketsCriteria,
    TransferRuleCriteria,
    AccommodationRuleCriteria,
    CarCriteria,
  },
  provide() {
    return {
      $validator: this.$validator,
    };
  },
  // see https://router.vuejs.org/guide/essentials/passing-props.html#object-mode
  props: {
    // readOnly: { type: Boolean, default: false },
    applicationId: { required: true, default: 0 },
    groupId: { required: true, default: 0 },
    extraId: { required: false, default: 0 },
  },
  data() {
    return {
      helper: helper,
      form: new Form(_.cloneDeep(helper.priceRuleModel)),
      pricingRuleChildComponent: "pricingRuleChildComponent",
      productTypesWithoutRule: [],
      displayProductTypeWarning: false,
      proceedSaving: false,
      priceBasisOptions: [
        { code: 1, label: 'Booking' },
        { code: 2, label: 'Passenger' },
      ],
    };
  },
  // props: [ 'companyId', 'roleId' ],
  computed: {
    readOnly() {
      if (this.$route.meta.readOnly) {
        return true
      }
      return false
    },
    priceRulesFormData() {
      return this.$store.state.customExtraStore.formData;
    },
    currencyOptions () {
      return (this.$store.state.customExtraStore && 
        this.$store.state.customExtraStore.formData && 
        this.$store.state.customExtraStore.formData.applicationCurrencies ) || []
    },
    preferedDateFormat() {
      return this.$store.state.accountStore.generalSettings.dateFormat;
    },
    pageTitle() {
      return this.readOnly
        ? "View Extra"
        : this.extraId > 0
          ? "Edit Extra : " + this.extraId
          : "Add Extra";
    },
    isEditForm() {
      return this.extraId > 0 ? true : false;
    },
    isSystemRole() {
      return this.$route.query.isSystem;
    },
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  created() {
    if (!customExtraStore.isRegistered) {
      this.$store.registerModule("customExtraStore", customExtraStore);
      customExtraStore.isRegistered = true;
    }

    if (!this.appHasFeature('customextras')) {
      this.$router.push('/error-404')
    }
    
    //#region fetch required data from api and store in state
    this.$vs.loading();
    this.$store.dispatch("customExtraStore/fetchFormData",
        this.applicationId
      )
      .then(() => {
        // if user's general setting is not loaded yet, load it
        let userDateFormat =
          this.$store.state.accountStore.generalSettings.dateFormat;
        if (!userDateFormat) {
          return this.$store.dispatch("accountStore/loadAccount");
        }
      })
      .catch((err) => console.error(err))
      .finally(() => this.$vs.loading.close());
    //#endregion

    //#region if this is Edit/View form, fetch data from api and bind it to form data
    if (this.extraId > 0) {
      this.$vs.loading();
      this.$http.get(`api/customextras/apps/${this.applicationId}/groups/${this.groupId}/extras/${this.extraId}`
        )
        .then((response) => {
          const dataToBeEdited = JSON.parse(response.data.ruleData);
          //check if data for given rule-id exist
          if (dataToBeEdited) {
            this.settingFormObjectToNullProductType(dataToBeEdited);
            this.form = Object.assign(this.form, dataToBeEdited);
          }

        })
        .catch((error) => {
          this.$_notifyFailureByResponseData(error.response.data);
          this.$router.push({name: 'application-tools-extra-groups-extra-list', 
            params: { 
              applicationId: this.applicationId,
              groupId: this.groupId 
            }
          })
        })
        .finally(() => this.$vs.loading.close());
    }
    if (!(this.extraId > 0)) {
      this.form.groupId = parseInt(this.groupId)
    }

    //#endregion
  },
  methods: {
    handleSubmit() {
      // It triggers validateform() method in all of its child component  which returns promise.
      let allForm = this.$refs.pricingRuleChildComponent.map((x) =>
        x.validateform()
      );
      //It triggers validation in this component itself and store the promise in allForm Array
      allForm.push(this.$validator.validateAll());
      Promise.all(allForm).then((result) => {
        if (!result.includes(false)) {
          if (!this.proceedSaving) {
            this.additionalCriteria(this.form.data());
          }
          if (this.proceedSaving) {
            this.$vs.loading();
            const action = this.isEditForm ? "customExtraStore/updateExtra" : "customExtraStore/addExtra";
            const payload = {
              applicationId: this.applicationId,
              groupId: this.groupId,
              extraId: this.extraId,
              data: this.form.data(),
            };
            this.settingNullToNotSelectedProductType(payload);

            this.$store
              .dispatch(action, payload)
              .then((response) => {
                const successMsg = this.isEditForm ? "updated" : "added new";
                this.$_notifySuccess(`Successfully ${successMsg} extra`);
                this.handleCancel()
              })
              .catch((error) =>
                this.$_notifyFailureByResponseData(error.response.data)
              )
              .finally(() => this.$vs.loading.close());
          }
        } else {
          this.$_notifyFailure(
            "Invalid form data. Please correct all form fields."
          );
        }
      });
    },
    settingNullToNotSelectedProductType(payload) {
      for (let i = 0; i < payload.data.productTypes.length; i++) {
        if (!payload.data.productTypes.includes("Flights")) {
          payload.data.flightRuleCriteria = null;
        }
        if (!payload.data.productTypes.includes("Accommodation")) {
          payload.data.accommodationRuleCriteria = null;
        }
        if (!payload.data.productTypes.includes("TicketsAttractions")) {
          payload.data.ticketsRuleCriteria = null;
        }
        if (!payload.data.productTypes.includes("Transfers")) {
          payload.data.transferRuleCriteria = null;
        }
        if (!payload.data.productTypes.includes("CarRental")) {
          payload.data.carRuleCriteria = null;
        }
        if (!payload.data.productTypes.includes("AirportExtras")) {
          payload.data.airportExtrasRuleCriteria = null;
        }
      }
    },
    settingFormObjectToNullProductType(dataToBeEdited) {
      if (dataToBeEdited.flightRuleCriteria == null) {
        dataToBeEdited.flightRuleCriteria = helper.flightCriteria;
      }
      if (dataToBeEdited.accommodationRuleCriteria == null) {
        dataToBeEdited.accommodationRuleCriteria = helper.accommodationCriteria;
      }
      if (dataToBeEdited.carRuleCriteria == null) {
        dataToBeEdited.carRuleCriteria = helper.carCriteria;
      }
      if (dataToBeEdited.transferRuleCriteria == null) {
        dataToBeEdited.transferRuleCriteria = helper.transferCriteria;
      }
      if (dataToBeEdited.ticketsRuleCriteria == null) {
        dataToBeEdited.ticketsRuleCriteria = helper.ticketsCriteria;
      }
      if (dataToBeEdited.airportExtrasRuleCriteria == null) {
        dataToBeEdited.airportExtrasRuleCriteria = helper.airportExtrasCriteria;
      }
    },
    additionalCriteria(formData) {
      this.productTypesWithoutRule = [];
      for (let i = 0; i < formData.productTypes.length; i++) {
        if (formData.productTypes[i] === "Flights") {
          if (
            Object.values(formData.flightRuleCriteria).some(
              (element) => element == null
            )
          ) {
            this.productTypesWithoutRule.push("Flights");
          }
        }
        if (formData.productTypes[i] === "Accommodation") {
          if (
            Object.values(formData.accommodationRuleCriteria).some(
              (element) => element == null
            )
          ) {
            this.productTypesWithoutRule.push("Accommodation");
          }
        }
        if (formData.productTypes[i] === "TicketsAttractions") {
          if (
            Object.values(formData.ticketsRuleCriteria).some(
              (element) => element == null
            )
          ) {
            this.productTypesWithoutRule.push("Tickets Attractions");
          }
        }
        if (formData.productTypes[i] === "Transfers") {
          if (
            Object.values(formData.transferRuleCriteria).some(
              (element) => element == null
            )
          ) {
            this.productTypesWithoutRule.push("Transfers");
          }
        }
        if (formData.productTypes[i] === "CarRental") {
          if (
            Object.values(formData.carRuleCriteria).some(
              (element) => element == null
            )
          ) {
            this.productTypesWithoutRule.push("CarRental");
          }
        }
        if (formData.productTypes[i] === "AirportExtras") {
          if (
            Object.values(formData.airportExtrasRuleCriteria).some(
              (element) => element == null
            )
          ) {
            this.productTypesWithoutRule.push("Airport Extras");
          }
        }
        if (
          i === formData.productTypes.length - 1 &&
          this.productTypesWithoutRule.length > 0
        ) {
          this.displayProductTypeWarning = true;
        }
      }
      if (this.productTypesWithoutRule.length > 0) {
        this.proceedSaving = false;
      } else {
        this.proceedSaving = true;
      }
    },
    handleCancel() {
      this.$router.push({name: 'application-tools-extra-groups-extra-list', 
        params: { 
          applicationId: this.applicationId,
          groupId: this.groupId 
        }
      })
    },
    handleEditMode() {
      this.$router.push({name: 'application-tools-extra-groups-extra-edit', 
        params: { 
          applicationId: this.applicationId,
          groupId: this.groupId,
          extraId: this.extraId
        }
      })
    },
    addDynamicFields(fieldType) {
      if (fieldType === "bookingDates") {
        this.form.bookingDates.push(_.cloneDeep(helper.dateRange));
      }
      if (fieldType === "travelDates") {
        this.form.travelDates.push(_.cloneDeep(helper.dateRange));
      }
      if (fieldType === "passenger") {
        this.form.passengerAllocations.push(
          _.cloneDeep(helper.passengerAllocation)
        );
      }
      if (fieldType === "priceRange") {
        this.form.prices.push(_.cloneDeep(helper.priceRange));
      }
    },
    removeDynamicFields(index, fieldType) {
      if (fieldType === "bookingDates") {
        this.form.bookingDates.splice(index, 1);
      }
      if (fieldType === "travelDates") {
        this.form.travelDates.splice(index, 1);
      }
      if (fieldType === "passenger") {
        this.form.passengerAllocations.splice(index, 1);
      }
      if (fieldType === "priceRange") {
        this.form.prices.splice(index, 1);
      }
    },
  },

};
</script>

<style>
</style>
